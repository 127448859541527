/**
 * 汎用JS
 */

// debounce
export function debounce(fn, interval) {
    let timer;
    return () => {
        clearTimeout(timer);
        timer = setTimeout(() => fn(), interval);
    }
}

// throttle
export function throttle(fn, interval) {
    let lastTime = Date.now() - interval;
    return () => {
        if( (lastTime + interval) < Date.now() ){
            lastTime = Date.now();
            fn();
        }
    }
}

// cookie取得
export function getCookie(searchKey) {
    const keyVal = document.cookie.split(';').find(keyVal => keyVal.split('=')[0].trim() == searchKey.trim());
    if( keyVal ){
        return keyVal.split('=')[1];
    }else{
        return false;
    }

}

// cookies駆除
export function deleteCookie(searchKey) {
    document.cookie = searchKey + '=; Max-Age=0; path=/;';
}

// throttle
export function nl2br(str) {
    return str.replace(/(\r\n|\r|\n)/g, '<br>');
}
