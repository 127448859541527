/**
 * 共通JS
 * サイドバーなど
 */

import { throttle, getCookie, deleteCookie } from './library';

class Common {
    constructor() {
        // APIトークンをlocalstorageに保存
        this.saveApiTokenToStorage();

        // SPナビゲーションバー
        this.toggleNav();

        // Cookieポップアップ
        this.cookiePopup();

        // header表示・非表示
        this.toggleHeader();

        // 伸縮するテキストエリア
        this.flexTextarea();

        // TOPへスクロールボタン表示・非表示
        this.scrollTopEl = document.getElementsByClassName('ScrollTop');
        if( this.scrollTopEl.length > 0 ){
            this.scrollTop(this.scrollTopEl[0]);
        }

        // スムーススクロール
        this.scrollLinkEls = document.querySelectorAll('a[href^="#"]');
        if( this.scrollLinkEls.length > 0 ){
            this.scrollLinkEls.forEach(el => el.addEventListener('click', ev => this.smoothScroll(el, ev)));
        }

        // 次の要素を発火
        this.fireNextEls = Array.from(document.getElementsByClassName('fire-next'));
        this.fireNextEls.forEach(el => el.addEventListener('click', ev => this.fireNext(el, ev)));

        // モーダル
        this.modalOpenBtnEls = Array.from(document.getElementsByClassName('modal-open'));
        this.modalOpenBtnEls.forEach(el => el.addEventListener('click', ev => this.openModal(el)));
        this.modalCloseBtnEls = document.querySelectorAll('.modal-close, .modal-cancel');
        this.modalCloseBtnEls.forEach(el => el.addEventListener('click', ev => this.closeModal(el)));
        if( document.querySelector('.modal-container') ){
            document.addEventListener('click', ev => {
                if( ev.target.closest('.modal-container') && ev.target.closest('.modal-container').classList.contains('active') ){
                    if( !ev.target.closest('.modal-body') ){
                        this.closeModal(ev.target);
                    }
                }
            });
        }

        // リモートにある画像を表示
        this.showImageEls = document.getElementsByClassName('show-image');
        if( this.showImageEls.length > 0 ){
            this.showImageFromRemote(this.showImageEls);
        }

        //クリックしてメニュー色変更
        this.tabColorChange();
    }

    /**
     * ログイン時にサーバーからAPIトークンがcookieに渡されるので
     * それをlocalStorageに保存してcookieから削除する
     */
    saveApiTokenToStorage() {
        const userToken = getCookie('user_api_token_forjs');
        if( userToken ){
            localStorage.setItem('user_api_token', userToken);
            deleteCookie('user_api_token');
        }
    }

    /**
     * SPナビゲーションバー表示・非表示
     */
    toggleNav() {
        const body = document.body;
        var hamburger = document.getElementById('Menu');
        const blackBg = document.getElementById("js-menu-bg");
        if( hamburger && blackBg ){
            hamburger.addEventListener('click', function() {
                body.classList.toggle('nav-open');
                hamburger.classList.toggle('active');
            });
            blackBg.addEventListener("click", function() {
                body.classList.remove("nav-open");
                hamburger.classList.remove("active");
            });
        }
    }

    /**
     * Cookie使用同意のポップアップ
     */
    cookiePopup() {
        const setWithExpiry = (key, value, expire) => {
            const current = new Date();
            expire = current.getTime() + expire * 24 * 3600 * 1000;
            const item = {
                value: value,
                expire: expire
            };
            localStorage.setItem(key, JSON.stringify(item));
        }

        const popup = () => cc.classList.add('is-show');

        const expire = 365; // 有効期限（日）
        let cc = document.querySelector('.cookie-consent');
        let ca = document.querySelector('.cookie-agree');
        let close = document.querySelector('.close');
        const flag = localStorage.getItem('popupFlag');
        if( flag != null ){
            const data = JSON.parse(flag);
            if( data['value'] == 'true' ){
                popup();
            } else {
                const current = new Date();
                if( current.getTime() > data['expire'] ){
                    setWithExpiry('popupFlag', 'true', expire);
                    popup();
                }
            }
        }else{
            setWithExpiry('popupFlag', 'true', expire);
            popup();
        }
        ca.addEventListener('click', () => {
            cc.classList.add('cc-hide1');
            setWithExpiry('popupFlag', 'false', expire);
        });
        close.addEventListener('click', () => {
            cc.classList.add('cc-hide1');
        });
    }

    /**
     * ヘッダー表示・非表示
     * 下スクロールで非表示、上スクロールで表示
     */
    toggleHeader() {
        let startPosition = 0;
        let windowPosition;
        let headerEl = document.querySelector('body > header');

        if( headerEl ){
            window.addEventListener('scroll',
                throttle(() => {
                    windowPosition = window.scrollY;

                    // スクロール位置がheader高さより小さい場合は必ずheader表示
                    if( windowPosition <= 113 ){
                        headerEl.style.top = null;
                    }
                    // スクロール量が初期位置のほうが大きければ、
                    // 上にスクロールしているのでヘッダーフッターを出現させる
                    else if( startPosition > windowPosition ){
                        headerEl.style.top = null;
                    }
                    // スクロール量が現在位置のほうが大きければ、
                    // 下にスクロールしているのでッダーフッターを隠す
                    else{
                        headerEl.style.top = '-113px';
                    }

                    // 現在の位置を更新する
                    startPosition = windowPosition;
                }, 100)
            );
        }
    }

    /**
     * 伸縮するテキストエリア
     */
    flexTextarea() {
        document.querySelectorAll('.flex-textarea').forEach(el => {
            const dummy = el.querySelector('.flex-textarea-dummy');
            const textarea = el.querySelector('.flex-textarea-textarea');
            // 初期設定
            dummy.textContent = textarea.value + '\u200b'

            // 文字が入力されたとき
            textarea.addEventListener('input', e => {
                dummy.textContent = e.target.value + '\u200b'
            })
        });
    }

    /**
     * TOPへスクロールボタン表示・非表示
     */
    scrollTop(el) {
        window.addEventListener('scroll',
            throttle(() => {
                if( window.scrollY < 40 ){
                    el.classList.remove('isActive');
                }else{
                    el.classList.add('isActive');
                }
            }, 1000)
        );
    }

    /**
     * #のついたリンク先へスムーススクロールする
     */
    smoothScroll(el, ev) {
        ev.preventDefault();

        const href = el.getAttribute('href');
        let position;
        if( href == '#' || href == '' ){
            position = 0;
        }else{
            const target = document.getElementById(href.replace('#', ''));
            if( target ){
                position = document.getElementById(href.replace('#', '')).offsetTop;
            }else{
                position = 0;
            }
        }console.log('position', position);
        window.scrollTo({
            top: position,
            behavior: 'smooth'
        });
    }

    /**
     * 次の要素を発火
     */
    fireNext(el, ev) {
        ev.preventDefault();
        el.nextElementSibling.click();
    }

    /**
     * モーダルを開く
     */
    openModal(el) {
        const containerEls = document.getElementById(el.dataset.target);
        containerEls.classList.add('active');
    }

    /**
     * モーダルを閉じる
     */
    closeModal(el) {
        const containerEls = el.closest('.modal-container');
        containerEls.classList.remove('active');
    }

    /**
     * リモートにある画像を表示
     */
    async showImageFromRemote(els) {
        let arr = [];
        for( const el of els ){
            arr.push({
                job_id: el.dataset.job_id,
                company_id: el.dataset.company_id,
                width: el.dataset.image_w,
                height: el.dataset.image_h,
                fit: el.dataset.image_fit,
                imagename: el.dataset.image_name
            });
        }

        const token = document.getElementsByName('csrf-token')[0].content;
        const url = document.getElementsByName('get-remote-image-url')[0].content;

        try{
            let response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': token,
                    'X-Requested-With': 'XMLHttpRequest'
                },
                body: JSON.stringify({
                    arr: arr
                })
            });

            response = await response.json();

            if( response.status == 'success' ){
                for( const item of response.result ){
                    let imgEls = document.querySelectorAll(`.show-image[data-image_name="${item.imagename}"]`);
                    imgEls.forEach(imgEl => imgEl.src = item.url)
                }
            }


        }catch( error ){
            //
        }
    }


    /**
     * クリックしてメニュー色変更
     */
    tabColorChange(){
        //最初のタグにクラス名与える
        const tab = document.getElementsByClassName('tab_item');
        if( tab.length > 0 ){
            tab[0].classList.toggle("is_this");

            //クラス名付ける
            for (var i = tab.length - 1; i >= 0; i--) {
                tabAction(tab[i],i);
            }

            function tabAction(tabDOM,tabId){
                tabDOM.addEventListener("click", function(){
                    this.classList.toggle('is_this');

                    for (var i = 0; i < tab.length; i++) {
                        if(tabId !== i){
                            if(tab[i].classList.contains('is_this')){
                                tab[i].classList.remove('is_this');
                            }
                        }
                    }
                })
            }
        }
    }
}

window.common = new Common();
